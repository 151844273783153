<template>

    <!--
    PRICES
    -->
    <b-card class="shadow rounded bg-white" no-body fluid>
        <b-card-header class="bg-light d-flex">
            <b-img src="/img/menu/prices.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
            <h3 class="text-secondary mb-0 mr-2">Prices</h3>
        </b-card-header>
        <b-card-body>
            <b-row>
                <b-col xl="6" xxl="4" class="mb-4">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex">
                            <b-img src="/img/menu/tenants.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Monthly Active Tenants (MAT)</h5>
                        </b-card-header>
                        <b-card-body>
                            <b-table outlined hover :items="tiers_mat" :fields="fields" primary-key="tier" class="mb-0">
                                <template #cell(price)="data">
                                    <a v-if="data.value === 'contact'" href="mailto:sales@quasr.io">Talk to us</a>
                                    <span v-else>{{ data.value }}</span>
                                </template>
                            </b-table>
                        </b-card-body>
                        <b-card-footer class="text-muted bg-light">
                            <small>Prices per unit per month exc. VAT</small>
                        </b-card-footer>
                    </b-card>
                </b-col>
                <b-col xl="6" xxl="4" class="mb-4">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex">
                            <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Monthly Active Accounts (MAA)</h5>
                        </b-card-header>
                        <b-card-body>
                            <b-table outlined hover :items="tiers_maa" :fields="fields" primary-key="tier" class="mb-0">
                                <template #cell(price)="data">
                                    <a v-if="data.value === 'contact'" href="mailto:sales@quasr.io">Talk to us</a>
                                    <span v-else>{{ data.value }}</span>
                                </template>
                            </b-table>
                        </b-card-body>
                        <b-card-footer class="text-muted bg-light">
                            <small>Prices per unit per month exc. VAT</small>
                        </b-card-footer>
                    </b-card>
                </b-col>
                <b-col xl="6" xxl="4" class="mb-4">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex">
                            <b-img src="/img/icons/code.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Monthly API Calls (MAC)</h5>
                        </b-card-header>
                        <b-card-body>
                            <b-table outlined hover :items="tiers_mac" :fields="fields" primary-key="tier" class="mb-0">
                                <template #cell(price)="data">
                                    <a v-if="data.value === 'contact'" href="mailto:sales@quasr.io">Talk to us</a>
                                    <span v-else>{{ data.value }}</span>
                                </template>
                            </b-table>
                        </b-card-body>
                        <b-card-footer class="text-muted bg-light">
                            <small>Prices per unit per month exc. VAT</small>
                        </b-card-footer>
                    </b-card>
                </b-col>
            </b-row>
        </b-card-body>
        <b-card-body>
            <b-overlay :show="loading">

                <!--
                LOADING
                -->
                <template #overlay>
                    <div class="text-center">
                        <b-spinner variant="primary"></b-spinner>
                        <p><small class="text-primary">{{ loading }}</small></p>
                    </div>
                </template>

                <!--
                CALCULATOR
                -->
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex">
                        <b-img src="/img/icons/calculator.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Calculator</h5>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col xl="6" xxl="4">
                                <b-form-group label="MAT" label-align-sm="right" label-cols-sm="3" description="The amount of Monthly Active Tenants (MAT), i.e. the amount of tenants you expect to be active during each month. An active tenant has at least 1 MAA.">
                                    <b-form-input v-model="calculator.input.mat" type="number"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col xl="6" xxl="4">
                                <b-form-group label="MAA" label-align-sm="right" label-cols-sm="3" description="The amount of Monthly Active Accounts (MAA), i.e. the amount of accounts you expect to be active during each month. Typical actions are login or account access.">
                                    <b-form-input v-model="calculator.input.maa" type="number"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col xl="6" xxl="4">
                                <b-form-group label-align-sm="right" label-cols-sm="3" description="The amount of Monthly API Calls (MAC), i.e. the amount of API calls you expect during each month. No worries, you get 10 free per MAA which should cover normal use.">
                                    <template #label>
                                        MAC<b-badge class="ml-2" variant="warning">NEW</b-badge>
                                    </template>
                                    <b-form-input v-model="calculator.input.mac" type="number"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-body class="pb-0">
                        <b-row>
                            <b-col xl="6" xxl="4" class="mb-4">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex">
                                        <b-img src="/img/icons/payg.svg" height="25px" width="25px" class="mr-2 mt-1" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2 mt-1">Pay As You Go</h5>
                                        <b-button class="ml-auto" size="sm" variant="outline-secondary" disabled>Default</b-button>
                                    </b-card-header>
                                    <b-card-header class="text-muted bg-light">
                                        <small>{{ `Quota: ${calculator.result.payg.quota.mat} MAT, ${calculator.result.payg.quota.maa} MAA & ${calculator.result.payg.quota.mac} MAC` }}</small>
                                    </b-card-header>
                                    <b-card-body>
                                        <b-row>
                                            <b-col class="text-center">
                                                <h1 :class="`text-${calculator.result.payg.variant}`">{{ `€ ${calculator.result.payg.price.toFixed(3)}` }}</h1>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                    <b-card-footer class="text-muted bg-light">
                                        <small>{{ `Price per month exc. VAT for ${calculator.result.input.mat} MAT, ${calculator.result.input.maa} MAA & ${calculator.result.input.mac} MAC` }}</small>
                                    </b-card-footer>
                                </b-card>
                            </b-col>
                            <b-col xl="6" xxl="4" class="mb-4">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex">
                                        <b-img src="/img/icons/grav.svg" height="25px" width="25px" class="mr-2 mt-1" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2 mt-1">Gravitate & Save</h5>
                                        <b-button v-if="!$store.state.payment?.id" class="ml-auto" size="sm" variant="outline-danger" to="/payment">Missing Payment</b-button>
                                        <b-button v-else class="ml-auto" size="sm" variant="outline-primary" v-on:click="$emit('show', 'create-subscription', { plan: 'grav', quota: { mat: calculator.result.grav.quota.mat, maa: calculator.result.grav.quota.maa } })">Subscribe</b-button>
                                    </b-card-header>
                                    <b-card-header class="text-muted bg-light">
                                        <small>{{ `Quota: ${calculator.result.grav.quota.mat} MAT, ${calculator.result.grav.quota.maa} MAA & ${calculator.result.grav.quota.mac} MAC` }}</small>
                                    </b-card-header>
                                    <b-card-body>
                                        <b-row>
                                            <b-col class="text-center">
                                                <h1 :class="`text-${calculator.result.grav.variant}`">{{ `€ ${calculator.result.grav.price.toFixed(3)}` }}</h1>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                    <b-card-footer class="text-muted bg-light">
                                        <small>{{ `Price per month exc. VAT for ${calculator.result.input.mat} MAT, ${calculator.result.input.maa} MAA & ${calculator.result.input.mac} MAC` }}</small>
                                    </b-card-footer>
                                </b-card>
                            </b-col>
                            <b-col xl="6" xxl="4" class="mb-4">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex">
                                        <b-img src="/img/icons/enterprise.svg" height="25px" width="25px" class="mr-2 mt-1" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2 mt-1">Enterprise</h5>
                                        <b-button class="ml-auto" size="sm" variant="outline-primary" href="mailto:sales@quasr.io">Contact</b-button>
                                    </b-card-header>
                                    <b-card-header class="text-muted bg-light">
                                        <small>Quota: <i>To Be Discussed</i></small>
                                    </b-card-header>
                                    <b-card-body>
                                        <b-row>
                                            <b-col class="text-center">
                                                <h1 :class="`text-${calculator.result.ent.variant}`">Talk to us</h1>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                    <b-card-footer class="text-muted bg-light">
                                        <small>Inc. 24x7 support & SLA</small>
                                    </b-card-footer>
                                </b-card>
                            </b-col>
                            <b-col xl="6" xxl="4" class="mb-4">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex">
                                        <b-img src="/img/icons/startup.svg" height="25px" width="25px" class="mr-2 mt-1" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2 mt-1">Startup</h5>
                                        <b-button class="ml-auto" size="sm" variant="outline-primary" href="mailto:sales@quasr.io">Apply</b-button>
                                    </b-card-header>
                                    <b-card-header class="text-muted bg-light">
                                        <small>{{ `Quota: ${calculator.result.strt.quota.mat} MAT, ${calculator.result.strt.quota.maa} MAA & ${calculator.result.strt.quota.mac} MAC` }}</small>
                                    </b-card-header>
                                    <b-card-body>
                                        <b-row>
                                            <b-col class="text-center">
                                                <h1 :class="`text-${calculator.result.strt.variant}`">{{ `€ ${calculator.result.strt.price.toFixed(3)}` }}</h1>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                    <b-card-footer class="text-muted bg-light">
                                        <small>{{ `Price per month exc. VAT for ${calculator.result.input.mat} MAT, ${calculator.result.input.maa} MAA & ${calculator.result.input.mac} MAC` }}</small>
                                    </b-card-footer>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-body class="d-flex">
                        <b-button variant="outline-primary" v-on:click="getPrices()">Calculate</b-button>
                    </b-card-body>
                </b-card>
            </b-overlay>
        </b-card-body>
        <b-card-footer class="text-muted bg-light">
            <small>Powered by <a href="https://stripe.com" target="_blank">Stripe</a></small>
        </b-card-footer>
    </b-card>

</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'tier',
        label: 'Tier',
        sortable: false
    },
    {
        key: 'price',
        label: 'Price',
        sortable: false
    }
];
const TIERS_MAT = [
    { tier: '1-24', price: '€ 5.00' },
    { tier: '25-49', price: '€ 3.00' },
    { tier: '50-99', price: '€ 2.25' },
    { tier: '100-499', price: '€ 1.75' },
    { tier: '500-999', price: '€ 1.50' },
    { tier: '1000+', price: '€ 0.75' },
    { tier: '>1000', price: 'contact' }
];
const TIERS_MAA = [
    { tier: '1-4999', price: '€ 0.183' },
    { tier: '5000-9999', price: '€ 0.156' },
    { tier: '10000-24999', price: '€ 0.138' },
    { tier: '25000-49999', price: '€ 0.119' },
    { tier: '50000+', price: '€ 0.106' },
    { tier: '>50000', price: 'contact' }
];
const TIERS_MAC = [
    { tier: '1+', price: '€ 0.004' },
    { tier: '>1000000', price: 'contact' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Prices',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // FIELDS
            fields: FIELDS,
            // TIERS (MAT)
            tiers_mat: TIERS_MAT,
            // TIERS (MAA)
            tiers_maa: TIERS_MAA,
            // TIERS (MAC)
            tiers_mac: TIERS_MAC,
            // CALCULATOR
            calculator: {
                input: {
                    mat: 3,
                    maa: 100,
                    mac: 1000
                },
                result: {
                    input: { mat: 0, maa: 0, mac: 0 },
                    payg: { price: 0, variant: 'black', quota: { mat: 0, maa: 0, mac: 0 } },
                    grav: { price: 0, variant: 'black', quota: { mat: 0, maa: 0, mac: 0 } },
                    strt: { price: 0, variant: 'black', quota: { mat: 0, maa: 0, mac: 0 } },
                    ent: { variant: 'black' }
                }
            }
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.$store.state.payment) {
            this.$emit('load', 'payment');
        }
        this.getPrices();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * prices
         */
        async getPrices() {
            this.loading = 'Calculating';
            try {

                // GET PRICES
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/account/prices?mat=${this.calculator.input.mat}&maa=${this.calculator.input.maa}&mac=${this.calculator.input.mac}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const result = await response.json();
                    this.calculator.result = {
                        input: {
                            mat: this.calculator.input.mat,
                            maa: this.calculator.input.maa,
                            mac: this.calculator.input.mac
                        },
                        payg: {
                            price: result.payg,
                            variant: this.getVariant(this.calculator.input, result, 'payg'),
                            quota: {
                                mat: 3,
                                maa: 100,
                                mac: Math.max(10 * this.calculator.input.maa, 1000)
                            }
                        },
                        grav: {
                            price: result.grav,
                            variant: this.getVariant(this.calculator.input, result, 'grav'),
                            quota: {
                                mat: Math.max(this.calculator.input.mat, 3),
                                maa: Math.max(this.calculator.input.maa, this.calculator.input.mat < 4 ? 101 : 100),
                                mac: Math.max(10 * this.calculator.input.maa, 1000)
                            }
                        },
                        strt: {
                            price: result.strt,
                            variant: this.getVariant(this.calculator.input, result, 'strt'),
                            quota: {
                                mat: 100,
                                maa: 25000,
                                mac: Math.max(10 * this.calculator.input.maa, 10000)
                            }
                        },
                        ent: {
                            variant: this.getVariant(this.calculator.input, result, 'ent')
                        }
                    };
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain prices.', 'Prices', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain prices.', 'Prices', 'danger');
            }
            this.loading = undefined;
        },

        getVariant(input, result, plan) {
            if (input.mat > 1000 || input.maa > 50000 || input.mac > 1000000) {
                return plan === 'ent' ? 'success' : 'black';
            } else if (result.payg <= result.grav) {
                return plan === 'payg' ? 'success' : 'black';
            } else {
                return plan === 'grav' ? 'success' : 'black';
            }
        }
    }
}
</script>