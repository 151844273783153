<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/invoices.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Invoices</h3>
                <b-form-input class="mx-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
            </b-card-header>
            <b-card-body v-if="$store.state.invoices">
                <b-table id="table" outlined hover :items="$store.state.invoices.items" :fields="fields" primary-key="id" sort-by="created" sort-desc :filter="search" class="mb-0" show-empty empty-text="You have no invoices." empty-filtered-text="No invoices match your search.">
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                    <template #cell(actions)="data">
                        <b-button :href="data.item.invoice_url" target="_blank" variant="outline-primary" size="sm" :disabled="!data.item.invoice_url">Open</b-button>
                        <b-button :href="data.item.invoice_pdf" class="ml-2" variant="outline-primary" size="sm" :disabled="!data.item.invoice_pdf">Download</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="$emit('load', 'invoices')">Refresh</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.invoices?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.invoices.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://stripe.com" target="_blank">Stripe</a></small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'number',
        label: 'Number',
        sortable: true
    },
    {
        key: 'subscription',
        label: 'Subscription',
        sortable: true
    },
    {
        key: 'plan',
        label: 'Plan',
        sortable: true,
        formatter: value => PLANS[value]
    },
    {
        key: 'total',
        label: 'Total',
        sortable: true,
        formatter: value => `€ ${(value / 100).toFixed(2)}`
    },
    {
        key: 'created',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value * 1000).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const PLANS = {
    'payg': 'Pay As You Go',
    'grav': 'Gravitate & Save',
    'strt': 'Startup',
    'ent': 'Enterprise'
};
const STATUSES = {
    'paid': 'Paid',
    'draft': 'Draft'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Invoices',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load' ],

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.$store.state.invoices) {
            this.$emit('load', 'invoices');
        }
    }
}
</script>