<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/customer.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Customer</h3>
            </b-card-header>
            <b-card-body v-if="$store.state.customer">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="$store.state.customer.id" readonly></b-form-input>
                            <b-badge style="position: absolute; right: 15px; bottom: 10px" variant="primary">YOU</b-badge>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="getCustomer()" >
                        <b-form-group label="Email" label-align-sm="right" label-cols-sm="3" description="This is the email we use to contact you." :state="validEmail()" :valid-feedback="$store.state.customer.email ? 'This email is verified.' : undefined" invalid-feedback="Please provide a valid email.">
                            <b-input-group>
                                <b-form-input v-model="this.customer.email" :state="validEmail()" :readonly="$store.state.customer.email"></b-form-input>
                                <b-input-group-append v-if="!$store.state.customer.email">
                                    <b-button variant="success" v-on:click="$emit('enroll', { id: 'fd26d514-7c72-4ba3-a60f-dccc475d971e', label: 'Email One-Time Password', subtype: 'otp', input: this.customer.email, config: {} })" :disabled="!validEmail()">Verify</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="$store.state.customer?.otp_emails.length">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'otp-mails')">
                        <b-img src="/img/factors/otp.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">One-Time Password Emails</h5>
                    </b-card-header>
                    <b-collapse id="otp-mails">
                        <b-card-body>
                            <b-row>
                                <b-col v-for="email in $store.state.customer.otp_emails" v-bind:key="email.id" xl="6" xxl="4">
                                    <b-form-group label="Email" label-align="right" label-cols-md="3" :description="`This email has been provided via an enrollment (${email.id}).`" :state="email.verified" valid-feedback="This email is verified." invalid-feedback="This email is not verified.">
                                        <b-form-input :value="email.email" :state="email.verified" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="$store.state.customer?.idp_emails.length">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'idp-mails')">
                        <b-img src="/img/factors/oauth2/oidc.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Identity Provider Emails</h5>
                    </b-card-header>
                    <b-collapse id="idp-mails">
                        <b-card-body>
                            <b-row>
                                <b-col v-for="email in $store.state.customer.idp_emails" v-bind:key="email.id" xl="6" xxl="4">
                                    <b-form-group label="Email" label-align="right" label-cols-md="3" :description="`This email has been provided via an enrollment (${email.id}).`" :state="email.verified" valid-feedback="This email is verified." invalid-feedback="This email is not verified.">
                                        <b-form-input :value="email.email" :state="email.verified" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="refreshCustomer()">Refresh</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.customer?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.customer.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://stripe.com" target="_blank">Stripe</a></small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const EMAIL_REGEX = new RegExp('^(([^<>()[ \\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Customer',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'enroll' ],

    /**
     * DATA
     */
    data() {
        return {
            // CUSTOMER
            customer: undefined
        }
    },

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        this.getCustomer(true);
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * CUSTOMER
         */
        getCustomer(reset) {
            if (!this.$store.state.customer) {
                this.$emit('load', 'customer');
                return false;
            } else if (!this.customer || reset) {
                this.resetCustomer();
            }
            return true;
        },

        refreshCustomer() {
            this.$emit('load', 'customer');
            setTimeout(this.resetCustomer, 2000);
        },

        resetCustomer() {
            this.customer = {
                email: this.$store.state.customer.email
            };
        },

        /**
         * VALIDATION
         */
        validEmail() {
            return EMAIL_REGEX.test(this.customer.email);
        }

    }
}
</script>