<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/consents.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Consents</h3>
                <b-form-input class="mx-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
            </b-card-header>
            <b-card-body v-if="$store.state.consents">
                <b-table id="table" outlined hover :items="$store.state.consents.items" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" class="mb-0" show-empty empty-text="You have no consents." empty-filtered-text="No consents match your search. If more consents are available you can load them by clicking 'Load More' below.">
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                    <template #cell(actions)="data">
                        <b-button variant="outline-danger" size="sm" :disabled="['PENDING','LOCKED'].includes(data.item.status)" v-on:click="$emit('show', 'delete-consent', data.item)">Delete</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="listConsents()">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.consents" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? $store.state.consents.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.consents?.nextToken" variant="outline-primary" v-on:click="nextConsents()" class="ml-2" :disabled="loading_more">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.consents?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.consents.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Consents',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined,
            // LOADING (MORE)
            loading_more: false
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.$store.state.consents) {
            this.listConsents();
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * CONSENTS
         */
        async listConsents() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listConsents($limit: Int) {
                                listConsents(limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const consents = (await response.json()).data.listConsents;
                    // ADD REFRESH DATE
                    consents.refreshed_at = new Date();
                    this.$store.commit('consents', consents);
                    // NOTIFY MORE AVAILABLE
                    if (consents.nextToken) {
                        this.$emit('alert', 'More consents are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Consents', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain consents.', 'Consents', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain consents.', 'Consents', 'danger');
            }
            this.loading = undefined;
        },

        async nextConsents() {
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listConsents($limit: Int, $nextToken: String) {
                                listConsents(limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "nextToken": "${this.$store.state.consents.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const consents = (await response.json()).data.listConsents;
                    // ADD NEW CONSENTS
                    for (const consent of consents.items) {
                        this.$store.commit('push_consent', consent);
                    }
                    // SET NEXT TOKEN
                    this.$store.commit('set_consents_token', consents.nextToken);
                    // NOTIFY MORE AVAILABLE
                    if (consents.nextToken) {
                        this.$emit('alert', 'More consents are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Consents', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain consents.', 'Consents', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain consents.', 'Consents', 'danger');
            }
            this.loading_more = false;
        }
    }
}
</script>