<!--
TEMPLATE
-->
<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <b-container class="bg-light" style="min-height: 100vh" fluid>

            <!--
            NAVBAR
            -->
            <b-row class="mx-0">
                <b-col class="p-0">
                    <b-navbar class="py-3" type="light" toggleable="lg">
                        <!-- LOGO -->
                        <b-navbar-brand>
                            <b-img src="/img/logo-blue.png" style="width: 8rem"></b-img>
                        </b-navbar-brand>
                        <!-- NAME -->
                        <b-navbard-nav>
                            <b-nav-text>
                                <h3 class="text-primary mb-0">Account<b-badge v-if="isRoot()" class="ml-2" variant="warning">NEW</b-badge></h3>
                            </b-nav-text>
                        </b-navbard-nav>
                        <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>
                        <b-collapse id="nav-collapse" is-nav>
                            <!-- GENERAL -->
                            <b-navbar-nav class="d-lg-none d-block">
                                <b-nav-item to="/">
                                    <b-img src="/img/menu/dashboard.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Dashboard</span>
                                </b-nav-item>
                                <b-nav-item to="/account">
                                    <b-img src="/img/menu/account.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Account</span>
                                    <b-badge class="ml-2" variant="solid">Solid</b-badge>
                                    <b-badge v-if="isRoot()" class="ml-2" variant="warning">NEW</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/enrollments">
                                    <b-img src="/img/menu/enrollments.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Enrollments</span>
                                    <b-badge v-if="$store.state.enrollments?.items" class="ml-2" variant="secondary">{{ $store.state.enrollments.items.length + ($store.state.enrollments.nextToken ? '+' : '') }}</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/permissions">
                                    <b-img src="/img/menu/permissions.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Permissions</span>
                                    <b-badge v-if="$store.state.permissions?.items" class="ml-2" variant="secondary">{{ $store.state.permissions.items.length + ($store.state.permissions.nextToken ? '+' : '') }}</b-badge>
                                    <b-badge v-if="isRoot()" class="ml-2" variant="warning">NEW</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/consents">
                                    <b-img src="/img/menu/consents.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Consents</span>
                                    <b-badge v-if="$store.state.consents?.items" class="ml-2" variant="secondary">{{ $store.state.consents.items.length + ($store.state.consents.nextToken ? '+' : '') }}</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/events">
                                    <b-img src="/img/menu/events.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Events</span>
                                    <b-badge v-if="$store.state.events?.items" class="ml-2" variant="secondary">{{ $store.state.events.items.length + ($store.state.events.nextToken ? '+' : '') }}</b-badge>
                                </b-nav-item>
                            </b-navbar-nav>
                            <!-- ROOT -->
                            <b-navbar-nav v-if="isRoot()" class="d-md-none d-block">
                                <b-nav-item to="/customer">
                                    <b-img src="/img/menu/customer.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Customer</span>
                                </b-nav-item>
                                <b-nav-item to="/tenants">
                                    <b-img src="/img/menu/tenants.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Tenants</span>
                                    <b-badge v-if="$store.state.tenants?.items" class="ml-2" variant="secondary">{{ $store.state.tenants.items.length + ($store.state.tenants.nextToken ? '+' : '') }}</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/subscriptions">
                                    <b-img src="/img/menu/subscriptions.svg" height="20px" class="mr-2" width="20px" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Subscriptions</span>
                                    <b-badge v-if="$store.state.subscriptions?.items" class="ml-2" variant="secondary">{{ $store.state.subscriptions.items.length }}</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/payment">
                                    <b-img src="/img/menu/payment.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Payment</span>
                                </b-nav-item>
                                <b-nav-item to="/invoices">
                                    <b-img src="/img/menu/invoices.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Invoices</span>
                                    <b-badge v-if="$store.state.invoices?.items" class="ml-2" variant="secondary">{{ $store.state.invoices.items.length }}</b-badge>
                                </b-nav-item>
                                <b-nav-item to="/prices">
                                    <b-img src="/img/menu/prices.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Prices</span>
                                </b-nav-item>
                            </b-navbar-nav>
                            <!-- PLATFORM -->
                            <b-navbar-nav v-if="isRoot()" class="ml-auto">
                                <b-nav-item href="https://docs.quasr.io" target="_blank">
                                    <b-img src="/img/menu/documentation.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Documentation</span>
                                </b-nav-item>
                                <b-nav-item href="https://discord.com/channels/895325971278856292/895413575491936257" target="_blank">
                                    <b-img src="/img/menu/community.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Community</span>
                                </b-nav-item>
                                <b-nav-item href="https://secure-stats.pingdom.com/1wgwg1ti7t35" target="_blank">
                                    <b-img src="/img/menu/monitoring.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                                    <span class="text-secondary">Monitoring</span>
                                </b-nav-item>
                            </b-navbar-nav>
                            <!-- LOGOUT -->
                            <b-button v-on:click="initiateLogin(true)" variant="outline-danger" :class="`ml-${isRoot() ? '2' : 'auto'}`">Logout</b-button>
                        </b-collapse>
                    </b-navbar>
                </b-col>
            </b-row>

            <!--
            VIEW
            -->
            <b-row class="pt-4 mx-0">
                <!-- MENU -->
                <b-col lg="2" class="d-none d-lg-block pl-0">
                    <!-- GENERAL -->
                    <b-nav vertical>
                        <b-nav-item to="/">
                            <b-img src="/img/menu/dashboard.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Dashboard</span>
                        </b-nav-item>
                        <b-nav-item to="/account">
                            <b-img src="/img/menu/account.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Account</span>
                            <b-badge class="ml-2" variant="solid">Solid</b-badge>
                            <b-badge v-if="isRoot()" class="ml-2" variant="warning">NEW</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/enrollments">
                            <b-img src="/img/menu/enrollments.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Enrollments</span>
                            <b-badge v-if="$store.state.enrollments?.items" class="ml-2" variant="secondary">{{ $store.state.enrollments.items.length + ($store.state.enrollments.nextToken ? '+' : '') }}</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/permissions">
                            <b-img src="/img/menu/permissions.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Permissions</span>
                            <b-badge v-if="$store.state.permissions?.items" class="ml-2" variant="secondary">{{ $store.state.permissions.items.length + ($store.state.permissions.nextToken ? '+' : '') }}</b-badge>
                            <b-badge v-if="isRoot()" class="ml-2" variant="warning">NEW</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/consents">
                            <b-img src="/img/menu/consents.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Consents</span>
                            <b-badge v-if="$store.state.consents?.items" class="ml-2" variant="secondary">{{ $store.state.consents.items.length + ($store.state.consents.nextToken ? '+' : '') }}</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/events">
                            <b-img src="/img/menu/events.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Events</span>
                            <b-badge v-if="$store.state.events?.items" class="ml-2" variant="secondary">{{ $store.state.events.items.length + ($store.state.events.nextToken ? '+' : '') }}</b-badge>
                        </b-nav-item>
                    </b-nav>
                    <!-- ROOT -->
                    <b-nav v-if="isRoot()" class="pt-4" vertical>
                        <b-nav-item to="/customer">
                            <b-img src="/img/menu/customer.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Customer</span>
                        </b-nav-item>
                        <b-nav-item to="/tenants">
                            <b-img src="/img/menu/tenants.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Tenants</span>
                            <b-badge v-if="$store.state.tenants?.items" class="ml-2" variant="secondary">{{ $store.state.tenants.items.length + ($store.state.tenants.nextToken ? '+' : '') }}</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/subscriptions">
                            <b-img src="/img/menu/subscriptions.svg" height="20px" class="mr-2" width="20px" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Subscriptions</span>
                            <b-badge v-if="$store.state.subscriptions?.items" class="ml-2" variant="secondary">{{ $store.state.subscriptions.items.length }}</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/payment">
                            <b-img src="/img/menu/payment.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Payment</span>
                        </b-nav-item>
                        <b-nav-item to="/invoices">
                            <b-img src="/img/menu/invoices.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Invoices</span>
                            <b-badge v-if="$store.state.invoices?.items" class="ml-2" variant="secondary">{{ $store.state.invoices.items.length }}</b-badge>
                        </b-nav-item>
                        <b-nav-item to="/prices">
                            <b-img src="/img/menu/prices.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${getFilter('secondary')}`"></b-img>
                            <span class="text-secondary">Prices</span>
                        </b-nav-item>
                    </b-nav>
                </b-col>
                <!-- VIEW -->
                <b-col class="align-items-center" lg="10" xxl="8">
                    <RouterView v-if="hasSession()" v-slot="{ Component }">
                        <component :is="Component" :loading="loading_view" :filter="getFilter" :variant="getVariant" :root="isRoot()" @alert="showAlert" @login="initiateLogin" @load="loadData" @next="loadNext" @show="showModal" @save="saveOutput" @enroll="createEnrollment"/>
                    </RouterView>
                </b-col>
                <!-- SPACE -->
                <b-col xxl="2" class="d-none d-xxl-block">
                </b-col>
            </b-row>

            <!--
            SYSTEM
            -->
            <b-row class="py-4 mx-0 w-100">
                <b-col class="text-muted text-center p-0">
                    <small>
                        <small v-if="isRoot()">{{ getRelease() }} | &copy; Copyright {{ new Date().getFullYear() }} Quasr BV</small>
                        <small v-else>{{ getRelease() }} | Powered by <a :href="getWebsite()" target="_blank">Quasr</a></small>
                    </small>
                </b-col>
            </b-row>

            <!-- CREATE TENANT -->
            <b-modal id="create-tenant" title="Create Tenant" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col>
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" description="Please note this label is visible to our administrators." :state="!!resource.label" invalid-feedback="Please provide a label.">
                            <b-form-input v-model="resource.label" :state="!!resource.label"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Subscription" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="resource.subscription" :options="$store.state.subscriptions.items" value-field="id" text-field="id"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('create-tenant')">Cancel</b-button>
                            <b-button variant="success" class="ml-auto" v-on:click="createTenant()" :disabled="!resource.label">Create</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- DELETE TENANT -->
            <b-modal id="delete-tenant" :title="`Delete Tenant (${resource?.label})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You're about to delete a tenant. Note that your tenant will first be locked before being deleted at the end of the month. All of the tenant resources will also be first locked and then deleted. During this time the tenant can be recovered if you contact us.
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-tenant')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="deleteData('tenant', resource.id)">Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- DELETE CONSENT -->
            <b-modal id="delete-consent" :title="`Delete Consent (${resource?.label})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You're about to delete a consent. This action can't be undone.
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-consent')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="deleteData('consent', resource.id)">Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- CREATE SUBSCRIPTION -->
            <b-modal id="create-subscription" title="Create Subscription" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col>
                        <b-form-group label="Plan" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="resource.plan" :options="plans"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="MAT" label-align-sm="right" label-cols-sm="3" description="This is the amount of Monthly Active Tenants (MAT) you're willing to commit to." :state="Number(resource.quota.mat) >= 3 && Number(resource.quota.mat) + Number(resource.quota.maa) > 103" invalid-feedback="The amount of MAT and MAA must be higher than the standard free quota, i.e. 3 MAT and 100 MAA.">
                            <b-form-input v-model="resource.quota.mat" type="number" :state="Number(resource.quota.mat) >= 3 && Number(resource.quota.mat) + Number(resource.quota.maa) > 103"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="MAA" label-align-sm="right" label-cols-sm="3" description="This is the amount of Monthly Active Accounts (MAA) you're willing to commit to." :state="Number(resource.quota.maa) >= 100 && Number(resource.quota.mat) + Number(resource.quota.maa) > 103" invalid-feedback="The amount of MAT and MAA must be higher than the standard free quota, i.e. 3 MAT and 100 MAA.">
                            <b-form-input v-model="resource.quota.maa" type="number" :state="Number(resource.quota.maa) >= 100 && Number(resource.quota.mat) + Number(resource.quota.maa) > 103"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-col>
                    <b-form-group label="" label-align-sm="right" label-cols-sm="3">
                        <b-form-checkbox v-model="resource.check">I understand that I'll be now charged.</b-form-checkbox>
                    </b-form-group>
                </b-col>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('create-subscription')">Cancel</b-button>
                            <b-button variant="success" class="ml-auto" v-on:click="createSubscription()" :disabled="!resource.check || Number(resource.quota.mat) < 3 || Number(resource.quota.maa) < 100 || Number(resource.quota.mat) + Number(resource.quota.maa) <= 103">Create</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- CANCEL SUBSCRIPTION -->
            <b-modal id="cancel-subscription" :title="`Cancel Subscription (${resource?.id})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You are about to cancel a subscription. This action can not be undone. Note that all of the tenants on the subscription will be moved back to your default Pay As You Go subscription.
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('cancel-subscription')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="cancelSubscription(resource.id)">Cancel</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- CREATE ENROLLMENT -->
            <b-modal id="create-enrollment" title="Create Enrollment" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col>
                        <b-form-group label="Factor" label-align-sm="right" label-cols-sm="3" :state="!!resource.id" invalid-feedback="Please select a factor.">
                            <b-form-select v-model="resource.id" :options="$store.state.factors.items" value-field="id" text-field="label" :state="!!resource.id" v-on:change="setFactor()"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" description="Please note this label is visible to our administrators.">
                            <b-form-input v-model="resource.label"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="resource.id && hasInput()">
                    <b-col>
                        <b-form-group :label="getLabel()" label-align-sm="right" label-cols-sm="3" :state="validInput()" invalid-feedback="Please provide valid input." :description="requiresInput() ? undefined : 'Leave this empty to let us generate one for you.'">
                            <b-form-file v-if="resource.subtype === 'jwt:spki'" v-model="resource.input" :state="validInput()" accept=".pem"></b-form-file>
                            <b-form-input v-else v-model="resource.input" :state="validInput()"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('create-enrollment')">Cancel</b-button>
                            <b-button variant="success" class="ml-auto" v-on:click="createEnrollment()">Create</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- DELETE ENROLLMENT -->
            <b-modal id="delete-enrollment" :title="`Delete Enrollment (${resource?.label})`" header-bg-variant="danger" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col class="text-center">
                        You're about to delete an enrollment. This action can not be undone. Please make sure to have sufficient enrollments on your account in order to still gain access.
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="$bvModal.hide('delete-enrollment')">Cancel</b-button>
                            <b-button variant="danger" class="ml-auto" v-on:click="deleteData('enrollment', resource.id)">Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- ENABLE ENROLLMENT -->
            <b-modal id="enable-enrollment" :title="`Enable Enrollment (${resource?.label})`" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
                <b-row>
                    <b-col>
                        <b-form-group :label="getLabel()" label-align-sm="right" label-cols-sm="3" :state="validInput()" invalid-feedback="Please provide valid input.">
                            <b-form-file v-if="resource.subtype === 'jwt:spki'" v-model="resource.input" :state="validInput()" accept=".pem"></b-form-file>
                            <b-form-input v-else v-model="resource.input" :state="validInput()"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button variant="outline-secondary" v-on:click="cancelEnrollment()">Cancel</b-button>
                            <span class="text-center m-auto">
                                <small v-if="timer.days || timer.hours || timer.minutes">
                                    <b v-if="timer.days"> {{ timer.days + (timer.days > 1 ? ' days ' : ' day ') }} </b>
                                    <b v-if="timer.hours"> {{ timer.hours + (timer.hours > 1 ? ' hours ' : ' hour ') }} </b>
                                    <b v-if="timer.minutes"> {{ timer.minutes + (timer.minutes > 1 ? ' minutes ' : ' minute ') }} </b>
                                    <b v-if="timer.seconds"> {{ timer.seconds + (timer.seconds > 1 ? ' seconds ' : ' second ') }} </b>
                                </small>
                                <small v-else class="text-danger">
                                    <b> {{ timer.seconds + (timer.seconds === 1 ? ' second ' : ' seconds ') }} </b>
                                </small>
                            </span>
                            <b-button variant="success" v-on:click="createEnrollment()" :disabled="!validInput()">Enable</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

            <!-- SAVE OUTPUT -->
            <b-modal id="save-output" :title="`Save Output (${resource?.label})`" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered hide-header-close>
                <b-row class="p-2">
                    <b-col class="text-center">
                        <b-img v-if="resource.subtype === 'totp'" :src="resource.output.image"></b-img>
                        <b-form-textarea v-else v-model="resource.output" size="sm" max-rows="5" no-resize readonly></b-form-textarea>
                    </b-col>
                </b-row>
                <b-row class="p-2">
                    <b-col class="text-center">
                        <span v-if="resource.subtype === 'totp'">Open your authenticator app, and scan above QR code. Alternatively you can also manually enter the setup key: <b>{{ resource.output.secret }}</b>. If asked, select "time-based"<br/>and label: {{ $store.state.account_id }}.</span>
                        <span v-else-if="resource.subtype === 'jwt:spki'">We've generated the above key pair for you. This is the only time you will be able to obtain it in clear so please make sure to save it now. The private key is what you will need for login.</span>
                        <span v-else-if="resource.subtype === 'jwt:bearer'">We generated above personal token for you. This is the only time you will be able to obtain it in clear so please make sure to save it now. Please note the token is only <b>valid for 1 year</b>.</span>
                        <span v-else>We've generated the above password for you. This is the only time you will be able to obtain it in clear so please make sure to save it now.</span>
                    </b-col>
                </b-row>
                <template #modal-footer>
                    <b-row class="w-100">
                        <b-col class="d-flex px-0">
                            <b-button v-if="resource.subtype === 'totp'" variant="primary" class="ml-auto" v-on:click="$bvModal.hide('save-output')">Done</b-button>
                            <b-button v-else variant="success" class="ml-auto" v-on:click="saveOutput()">{{ resource.subtype.startsWith('jwt') ? 'Download' : 'Copy' }}</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

        </b-container>
    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>
/**
 * IMPORTS
 */
import RandExp from 'randexp';
import * as PKCE_CHALLENGE from 'pkce-challenge';
import { jwtVerify, createRemoteJWKSet, importPKCS8, SignJWT } from 'jose';
import QR from 'qrcode';

/**
 * CONFIGURATION
 */
const ENVIRONMENT = 'prod';
const BASIC_AUTHZ = '';
const UPDATE_DATE = '2024.09.11';
const ROOT_TENANT = 'b62a482d-7365-4ae9-85a5-1453b3b0d5b7';
const ROOT_CLIENT = '9887d864-9426-4bc0-9b6d-aef0ad7f6b6f';
const DOMAIN = ENVIRONMENT === 'prod' ? '.quasr.io' : `-${ENVIRONMENT}.quasr.io`;
const ID_REGEX = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}');
const NONCE_REGEX = new RandExp('[a-zA-Z0-9]{43}');
const PLANS = [
    { value: 'grav', text: 'Gravitate & Save' }
];

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'App',

    /**
     * DATA
     */
    data() {
        return {
            // TENANT
            tenant_id: ROOT_TENANT,
            // CLIENT
            client_id: ROOT_CLIENT,
            // LOADING
            loading: undefined,
            // LOADING (VIEW)
            loading_view: undefined,
            // RESOURCE
            resource: undefined,
            // PLANS
            plans: PLANS,
            // TIMER
            timer: { days: 0, hours: 0, minutes: 0, seconds: 0, timeout: undefined }
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        this.loading = 'Initializing';
        const { code, id_token, id, input, error, error_id } = await this.initialize();
        // LOGIN
        if (code && id_token) {
            await Promise.all([this.processToken(id_token), this.processCode(code)]);
            this.clearState();
            this.$router.push('/'); // REMOVE QUERY
        }
        // SESSION
        if (this.hasSession() && !this.checkExpiration()) {
            this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
            this.clearSession();
            return this.initiateLogin(); // KEEP LOADING
        } else if (!this.hasSession()) {
            return this.initiateLogin(); // KEEP LOADING
        }
        this.$store.commit('session', this.getSession());
        this.$store.commit('account_id', this.getAccount());
        // ENROLLMENT
        if (id && input) {
            this.resource = { id: id, input: input };
            this.createEnrollment();
        } else if (error) {
            if (!error_id) {
                this.showAlert(this.getErrorMessage(error), 'Authentication', 'danger', 5000);
            } else {
                this.showAlert(`The system encountered an unexpected error. Please try another option or contact your administrator. You can mention this reference: ${error_id}.`, 'Authentication', 'danger');
            }
            this.$router.push('/enrollments');
        }
        this.loading = undefined;
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            // DOMAIN
            this.$store.commit('domain', DOMAIN);
            // TENANT ID
            const params = new URLSearchParams(window.location.search);
            const tenant_id = location.host.split('.')[0];
            if (ID_REGEX.test(tenant_id)) {
                this.tenant_id = tenant_id;
            } else if (params.has('tenant_id')) {
                this.tenant_id = params.get('tenant_id');
            }
            this.$store.commit('tenant_id', this.tenant_id);
            // LOGIN
            return {
                code: params.get('code'),
                id_token: params.get('id_token'),
                id: params.get('id'),
                input: params.get('input'),
                error: params.get('error'),
                error_id: params.get('error_id')
            };
        },

        /**
         * AUTHORIZATION
         */
        getAuthorization() {
            if (BASIC_AUTHZ) {
                return `Basic ${BASIC_AUTHZ}`;
            } else {
                return undefined;
            }
        },

        hasAuthorization() {
            return !!BASIC_AUTHZ;
        },

        /**
         * LOGIN
         */
        async initiateLogin(logout) {
            this.loading = `Logging ${logout ? 'Out' : 'In'}`;
            
            // CLEAR SESSION
            this.clearSession();

            // PREPARE STATE
            const nonce = NONCE_REGEX.gen();
            const code = await PKCE_CHALLENGE.default();
            await this.setState(nonce, code.code_verifier);

            // PREPARE REDIRECT
            const redirect = new URL(`https://api${DOMAIN}/oauth2/authorize`);
            redirect.searchParams.append('client_id', this.client_id);
            redirect.searchParams.append('response_type', `code id_token`);
            redirect.searchParams.append('code_challenge', code.code_challenge);
            redirect.searchParams.append('code_challenge_method', 'S256');
            redirect.searchParams.append('nonce', nonce); // ID TOKEN
            redirect.searchParams.append('redirect_uri', document.location.origin); // OPTIONAL
            redirect.searchParams.append('scope', `openid https://api${DOMAIN}/scopes/account`);
            if (logout) redirect.searchParams.append('prompt', 'login');

            // PERFORM REDIRECT
            document.location.href = redirect.href;
            this.loading = 'Redirecting to Quasr Login';
        },

        async processToken(id_token) {
            try {
                
                // VERIFY TOKEN
                const payload = (await jwtVerify(id_token, createRemoteJWKSet(new URL(`https://api${DOMAIN}/.well-known/jwks.json`)), {
                    issuer: `https://${this.tenant_id}.api${DOMAIN}`,
                    audience: this.client_id
                })).payload;

                // VERIFY NONCE
                if (payload.nonce === this.getNonce()) {
                    this.setAccount(payload.sub);
                } else {
                    this.showAlert('Failed to accept identity token.', 'Authentication', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to accept identity token.', 'Authentication', 'danger');
            }
        },

        async processCode(code) {
            try {

                // EXCHANGE CODE
                const response = await fetch(`https://${this.tenant_id}.api${DOMAIN}/oauth2/token`, {
                    method: 'POST',
                    body: JSON.stringify({
                        client_id: this.client_id,
                        grant_type: 'authorization_code',
                        code: code,
                        code_verifier: this.getCodeVerifier()
                    }),
                    headers: this.hasAuthorization() ? {
                        'Content-Type': 'application/json', 
                        Authorization: this.getAuthorization()
                    } : {
                        'Content-Type': 'application/json'
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tokens = await response.json();
                    this.setSession(tokens.access_token, tokens.expires_at);
                } else {
                    this.showAlert('Failed to obtain access token.', 'Authorization', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to obtain access token.', 'Authorization', 'danger');
            }
        },

        /**
         * STATE
         */
        getNonce() {
            return localStorage.getItem(`${this.tenant_id}#NONCE`);
        },

        getCodeVerifier() {
            return localStorage.getItem(`${this.tenant_id}#CODE_VERIFIER`);
        },

        hasState() {
            return this.getNonce() !== null;
        },

        async setState(nonce, code_verifier) {
            localStorage.setItem(`${this.tenant_id}#NONCE`, nonce);
            localStorage.setItem(`${this.tenant_id}#CODE_VERIFIER`, code_verifier);
        },

        async clearState() {
            localStorage.removeItem(`${this.tenant_id}#NONCE`);
            localStorage.removeItem(`${this.tenant_id}#CODE_VERIFIER`);
        },

        /**
         * SESSION
         */
        getSession() {
            return localStorage.getItem(`${this.tenant_id}#SESSION`);
        },

        getAccount() {
            return localStorage.getItem(`${this.tenant_id}#ACCOUNT`);
        },

        hasSession() {
            return this.getSession() !== null;
        },

        setSession(session, expiration) {
            localStorage.setItem(`${this.tenant_id}#SESSION`, session);
            localStorage.setItem(`${this.tenant_id}#EXPIRATION`, expiration);
        },

        setAccount(account) {
            localStorage.setItem(`${this.tenant_id}#ACCOUNT`, account);
        },

        clearSession() {
            localStorage.removeItem(`${this.tenant_id}#SESSION`);
            localStorage.removeItem(`${this.tenant_id}#EXPIRATION`);
            localStorage.removeItem(`${this.tenant_id}#ACCOUNT`);
        },

        getExpiration() {
            return parseInt(localStorage.getItem(`${this.tenant_id}#EXPIRATION`)) * 1000;
        },

        checkExpiration() {
            return (new Date().getTime()) < this.getExpiration();
        },

        /**
         * ALERT
         */
         async showAlert(message, title, variant, delay) {
            this.$bvToast.toast(message, {
                title: title,
                // toaster: 'b-toaster-top-center',
                variant: variant,
                autoHideDelay: delay,
                noAutoHide: !delay
            });
        },

        /**
         * STATUS
         */
        getVariant(status) {
            switch (status) {
                case 'LOCKED':
                case 'FAILED':
                    return 'danger';
                case 'PENDING':
                case 'draft':
                    return 'warning';
                case 'ENABLED':
                case 'SUCCESS':
                case 'active':
                case 'paid':
                    return 'success';
                default: // DISABLED / INACTIVE
                    return 'secondary';
            }
        },

        /**
         * FIlTER
         * 
         * See: https://codepen.io/sosuke/pen/Pjoqqp
         */
        getFilter(variant) {
            switch (variant) {
                case 'primary':
                    return 'invert(48%) sepia(15%) saturate(3187%) hue-rotate(183deg) brightness(89%) contrast(89%)';
                case 'secondary':
                    return 'invert(45%) sepia(6%) saturate(672%) hue-rotate(167deg) brightness(98%) contrast(88%)';
                case 'success':
                    return 'invert(52%) sepia(23%) saturate(1324%) hue-rotate(81deg) brightness(96%) contrast(94%)';
                case 'info':
                    return 'invert(69%) sepia(11%) saturate(4319%) hue-rotate(140deg) brightness(77%) contrast(83%)';
                case 'warning':
                    return 'invert(78%) sepia(84%) saturate(2275%) hue-rotate(355deg) brightness(101%) contrast(102%)';
                case 'danger':
                    return 'invert(33%) sepia(100%) saturate(897%) hue-rotate(320deg) brightness(85%) contrast(107%)';
                case 'light':
                    return 'invert(98%) sepia(3%) saturate(517%) hue-rotate(97deg) brightness(106%) contrast(94%)';
                default: // DARK
                    return 'invert(19%) sepia(8%) saturate(952%) hue-rotate(169deg) brightness(91%) contrast(85%)';
            }
        },

        /**
         * SYSTEM
         */
        getWebsite() {
            return `https://www${DOMAIN}`;
        },

        getRelease() {
            return this.isProduction() ? UPDATE_DATE : `${UPDATE_DATE}-${ENVIRONMENT}`;
        },

        isRoot() {
            return this.tenant_id === ROOT_TENANT;
        },

        isProduction() {
            return ENVIRONMENT === 'prod';
        },

        /**
         * MODALS
         */
        showModal(name, resource) {
            if (resource) this.resource = resource;
            this.$bvModal.show(name);
        },

        /**
         * DATA
         */
        async loadData(resource, all) {
            this.loading_view = 'Loading';
            try {

                // GET RESPONSE
                const response = await this.getResponse(resource);

                // VERIFY RESPONSE
                if (response.ok) {
                    const resource_json = await this.parseResponse(resource, response);
                    // ADD REFRESH DATE
                    resource_json.refreshed_at = new Date();
                    this.$store.commit(resource, resource_json);
                    // MORE AVAILABLE
                    if (resource_json.nextToken) {
                        if (all) {
                            return this.loadNext(resource, resource_json.nextToken, all); // KEEP LOADING
                        } else {
                            this.showAlert(`More ${resource} are available but were not loaded due to preserve bandwidth. You can load them by clicking 'Load More' below.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert(`Failed to load ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
                }

            } catch (error) {
                this.showAlert(`Failed to load ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
            }
            this.loading_view = undefined;
        },

        async loadNext(resource, nextToken, all) {
            this.loading_view = 'Loading';
            try {

                // GET RESPONSE
                const response = await this.getResponse(resource, nextToken);

                // VERIFY RESPONSE
                if (response.ok) {
                    const resource_json = await this.parseResponse(resource, response);
                    // ADD NEW TENANTS
                    for (const tenant of resource_json.items) {
                        this.$store.commit(`push_${resource.slice(0, -1)}`, tenant);
                    }
                    // SET NEXT TOKEN
                    this.$store.commit(`set_${resource}_token`, resource_json.nextToken);
                    // MORE AVAILABLE
                    if (resource_json.nextToken) {
                        if (all) {
                            return this.loadNext(resource, resource_json.nextToken, all); // KEEP LOADING
                        } else {
                            this.showAlert(`More ${resource} are available but were not loaded due to preserve bandwidth. You can load them by clicking 'Load More' below.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert(`Failed to load ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
                }

            } catch (error) {
                this.showAlert(`Failed to load ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
            }
            this.loading_view = false;
        },

        async getResponse(resource, nextToken) {
            switch (resource) {

                // GRAPHQL
                case 'tenants':
                    return await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                        method: 'POST',
                        body: JSON.stringify({
                            query: `
                                query listTenants($limit: Int${nextToken ? ', $nextToken: String' : ''}) {
                                    listTenants(limit: $limit${nextToken ? ', nextToken: $nextToken' : ''}) {
                                        items {
                                            id
                                            label
                                            status
                                            admin_client
                                            subscription
                                            created_at
                                            metrics {
                                                maa
                                                mac
                                                updated_at
                                            }
                                        }
                                        nextToken
                                    }
                                }
                            `,
                            variables: `{
                                "limit": 50${!nextToken ? '' : `,
                                "nextToken": "${nextToken}"`}
                            }`
                        }),
                        headers: {
                            Authorization: `Bearer ${this.$store.state.session}`
                        }
                    });
                
                case 'enrollments':
                    return await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                        method: 'POST',
                        body: JSON.stringify({
                            query: `
                                query listEnrollments($limit: Int${nextToken ? ', $nextToken: String' : ''}) {
                                    listEnrollments(limit: $limit${nextToken ? ', nextToken: $nextToken' : ''}) {
                                        items {
                                            id
                                            label
                                            score
                                            status
                                            subtype
                                            created_at
                                        }
                                        nextToken
                                    }
                                }
                            `,
                            variables: `{
                                "limit": 50${!nextToken ? '' : `,
                                "nextToken": "${nextToken}"`}
                            }`
                        }),
                        headers: {
                            Authorization: `Bearer ${this.$store.state.session}`
                        }
                    });

                case 'factors':
                    return await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                        method: 'POST',
                        body: JSON.stringify({
                            query: `
                                query listFactors($limit: Int${nextToken ? ', $nextToken: String' : ''}) {
                                    listFactors(limit: $limit${nextToken ? ', nextToken: $nextToken' : ''}) {
                                        items {
                                            id
                                            label
                                            subtype
                                            config {
                                                regex
                                            }
                                        }
                                        nextToken
                                    }
                                }
                            `,
                            variables: `{
                                "limit": 50${!nextToken ? '' : `,
                                "nextToken": "${nextToken}"`}
                            }`
                        }),
                        headers: {
                            Authorization: `Bearer ${this.$store.state.session}`
                        }
                    });

                // REST
                default:
                    return await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/account/${resource}`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${this.$store.state.session}`
                        }
                    });
            }
        },

        async parseResponse(resource, response) {
            switch (resource) {

                // GRAPHQL
                case 'tenants':
                case 'enrollments':
                case 'factors':
                    return (await response.json()).data[`list${resource.charAt(0).toUpperCase() + resource.slice(1)}`];

                // REST (LIST)
                case 'subscriptions':
                case 'invoices':
                    return { items: await response.json() };

                // REST
                default:
                    return await response.json();
            }
        },

        async deleteData(resource, id) {
            this.loading_view = 'Deleting';
            this.$bvModal.hide(`delete-${resource}`);
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation delete${resource.charAt(0).toUpperCase() + resource.slice(1)}($input: Delete${resource.charAt(0).toUpperCase() + resource.slice(1)}Input!) {
                                delete${resource.charAt(0).toUpperCase() + resource.slice(1)}(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${id}"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.showAlert(`Your ${resource} has been deleted.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'success', 5000);
                    this.loadData(`${resource}s`);
                    this.$router.push(`/${resource}s`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert(`Failed to delete ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
                }

            } catch (error) {
                this.showAlert(`Failed to delete ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
            }
            this.loading_view = undefined;
        },

        /**
         * TENANT
         */
        async cancelSubscription(id) {
            this.loading_view = 'Cancelling';
            this.$bvModal.hide('cancel-subscription');
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/account/subscription`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: id,
                        cancel_at_period_end: true
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.showAlert('Your subscription has been cancelled. It will remain active till end of the month.', 'Subscription', 'success', 5000);
                    this.loadData('subscriptions');
                    this.$router.push('/subscriptions');
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert('Failed to cancel subscription.', 'Subscription', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to cancel subscription.', 'Subscription', 'danger');
            }
            this.loading_view = undefined;
        },

        async createSubscription() {
            this.loading_view = 'Creating';
            this.$bvModal.hide('create-subscription');
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/account/subscription`, {
                    method: 'POST',
                    body: JSON.stringify({
                        plan: this.resource.plan,
                        quota: this.resource.quota
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.showAlert('Your subscription has been created.', 'Subscription', 'success', 5000);
                    this.loadData('subscriptions');
                    this.$router.push('/subscriptions');
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert('Failed to create subscription.', 'Subscription', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to create subscription.', 'Subscription', 'danger');
            }
            this.loading_view = undefined;
        },

        /**
         * TENANT
         */
        async createTenant() {
            this.loading_view = 'Creating';
            this.$bvModal.hide('create-tenant');
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation createTenant($input: CreateTenantInput!) {
                                createTenant(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "label": "${this.resource.label}",
                                "subscription": "${this.resource.subscription}"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tenant = (await response.json()).data.createTenant;
                    this.showAlert('Your tenant has been created and is currently being finished. This usually only takes a couple minutes.', 'Tenant', 'success', 5000);
                    this.loadData('tenants');
                    this.$router.push(`/tenants/${tenant.id}`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert('Failed to create tenant.', 'Tenant', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to create tenant', 'Tenant', 'danger');
            }
            this.loading_view = undefined;
        },

        /**
         * ENROLLMENT
         */
        hasInput() {
            switch (this.resource.subtype) {
                case 'secret:id':
                case 'secret:password':
                case 'otp':
                case 'jwt:spki':
                case 'jwt:jwks':
                    return true;
                default:
                    return false;
            }
        },

        requiresInput() {
            switch (this.resource.subtype) {
                case 'secret:id':
                case 'otp':
                case 'jwt:jwks':
                    return true;
                default:
                    return false;
            }
        },

        validInput() {
            if (this.resource.type !== 'enrollment' && !this.resource.input && !this.requiresInput()) return null;
            if (this.resource.subtype === 'jwt:spki') return !!this.resource.input?.name;
            return new RegExp(this.resource.config.regex).test(this.resource.input);
        },

        getLabel() {
            switch (this.resource.subtype) {
                case 'secret:id':
                    return this.resource.label || 'Username';
                case 'secret:password':
                    return this.resource.label || 'Password';
                case 'otp':
                    if (this.resource.type === 'enrollment') {
                        return 'Password';
                    } else {
                        return this.resource.label?.split(' ')[0] || 'Channel';
                    }
                case 'totp':
                    return 'Password';
                case 'jwt:spki':
                    if (this.resource.type === 'enrollment') {
                        return 'Private Key';
                    } else {
                        return 'Public Key';
                    }
                case 'jwt:jwks':
                    if (this.resource.type === 'enrollment') {
                        return 'Signed Token';
                    } else {
                        return 'JWKS URL';
                    }
                default:
                    return 'Input'
            }
        },

        setFactor() {
            const factor = this.$store.state.factors.items.find(factor => factor.id === this.resource.id);
            this.resource = {
                id: factor.id,
                label: factor.label,
                subtype: factor.subtype,
                config: {
                    regex: factor.config.regex
                }
            }
        },

        async cancelEnrollment() {
            this.$bvModal.hide('enable-enrollment');
            this.clearTimer();
        },

        async createEnrollment(resource) {
            this.loading_view = 'Creating';
            this.$bvModal.hide('create-enrollment');
            this.$bvModal.hide('enable-enrollment');
            this.clearTimer();
            if (resource) this.resource = resource;
            if (!this.resource.input) delete this.resource.input;
            // MODIFY INPUT
            if (this.resource.subtype.startsWith('jwt')) {
                if (this.resource.input?.name) {
                    const reader = new FileReader();
                    reader.onerror = () => {
                        this.showAlert('Failed to read file.', 'Enrollment', 'danger', 5000);
                        this.loading_view = undefined;
                    };
                    reader.onload = async () => {
                        this.resource.input = reader.result;
                        await this.createEnrollment(this.resource);
                    };
                    reader.readAsText(this.resource.input);
                    return; // KEEP LOADING
                } else if (this.resource.type === 'enrollment') {
                    this.resource.input = await new SignJWT().
                        setProtectedHeader({ alg: 'RS256' }).
                        setSubject(this.$store.state.account_id).
                        setIssuer(this.$store.state.account_id).
                        setAudience(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/oauth2/token`).
                        setExpirationTime('5m').
                        sign(await importPKCS8(this.resource.input, 'RS256'));
                }
            }
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/factors`, {
                    method: 'POST',
                    body: JSON.stringify([this.resource]),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const response_json = await response.json();
                    switch (response_json.result) {
                        case 'PENDING':
                            this.showAlert('Your enrollment has been created.', 'Enrollment', 'success', 5000);
                            if (this.resource.subtype === 'totp') {
                                response_json.feedback.output = {
                                    image: await QR.toDataURL(response_json.feedback.initialization_url),
                                    secret: response_json.feedback.secret
                                };
                            } else if (this.resource.subtype.startsWith('oauth2')) {
                                document.location.href = response_json.feedback.authorization_url;
                                this.loading = `Redirecting to ${this.getProviderName(this.resource.subtype.split(':')[1])}`;
                                return; // KEEP LOADING
                            }
                            this.resource.id = response_json.feedback.enrollment_id;
                            this.resource.type = 'enrollment';
                            this.resource.input = undefined;
                            this.resource.config.regex = response_json.feedback.regex;
                            if (response_json.feedback.expires_at) {
                                this.startTimer(new Date(response_json.feedback.expires_at).getTime());
                            }
                            this.showModal('enable-enrollment');
                        case 'SUCCESS':
                            if (response_json.result === 'SUCCESS') {
                                this.showAlert('Your enrollment has been created.', 'Enrollment', 'success', 5000);
                                if (this.resource.subtype === 'otp' || this.resource.subtype.startsWith('oauth2')) {
                                    setTimeout(this.loadData, 2000, 'customer');
                                }
                            }
                            if (response_json.feedback.output) {
                                this.resource.output = response_json.feedback.output;
                                this.showModal('save-output');
                            }
                            this.loadData('enrollments');
                            this.$router.push(`/enrollments/${response_json.feedback.enrollment_id}`);
                            break;
                        default: // FAILED
                            this.showAlert(this.getErrorMessage(response_json.feedback.cause), 'Enrollment', 'danger', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.showAlert('Your session has expired.', 'Authentication', 'warning', 5000);
                    this.initiateLogin();
                } else {
                    this.showAlert('Failed to create enrollment.', 'Enrollment', 'danger');
                }

            } catch (error) {
                this.showAlert('Failed to create enrollment.', 'Enrollment', 'danger');
            }
            this.loading_view = undefined;
        },

        getProviderName(subtype) {
            switch (subtype) {
                case 'linkedin':
                    return 'LinkedIn';
                case 'github':
                    return 'GitHub';
                case 'oidc':
                    return 'Identity Provider';
                default:
                    return subtype.charAt(0).toUpperCase() + subtype.slice(1);
            }
        },

        getErrorMessage(error) {
            switch(error) {
                case 'MISSING_INPUT':
                    return 'Please provide input.';
                case 'INVALID_INPUT':
                    return 'This input can\'t be used.';
                case 'RESERVED_INPUT':
                    return 'This input is already taken.';
                case 'INCORRECT_INPUT':
                    return 'This input is not correct.';
                case 'ENROLLMENT_NOT_FOUND':
                    return 'This user is not yet registered.';
                case 'ENROLLMENT_ALREADY_EXISTS':
                    return 'This user is already registered.';
                case 'ENROLLMENT_MISMATCH':
                    return 'This user is not the correct one.';
                case 'REJECTED_LOGIN':
                    return 'The identity provider indicated login was rejected.';
                default:
                    return 'The system encountered an unexpected error. Please try another option or contact your administrator.'
            }
        },

        /**
         * OUTPUT
         */
        async saveOutput(resource) {
            this.loading_view = 'Saving';
            this.$bvModal.hide('save-output');
            if (resource) this.resource = resource;
            // Save output
            if (this.resource.subtype.startsWith('jwt')) {
                // Prepare files
                const files = [];
                if (this.resource.subtype === 'jwt:spki') {
                    const outputs = this.resource.output.split(';');
                    if (outputs.length > 1) {
                        files.push(new File([outputs[0]], 'private_key.pem', { type: 'application/x-pem-file' }));
                        files.push(new File([outputs[1]], 'public_key.pem', { type: 'application/x-pem-file' }));
                    } else {
                        files.push(new File([outputs[0]], 'public_key.pem', { type: 'application/x-pem-file' }));
                    }
                } else {
                    files.push(new File([this.resource.output], 'personal_token.jwt', { type: 'application/jwt' }))
                }
                // Download files
                for (const file of files) {
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(file);
                    // Trigger download
                    link.href = url;
                    link.download = file.name;
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    // Cleanup download
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    this.showAlert(`P${file.name.split('.')[0].replace('_',' ').slice(1)} downloaded to file.`, 'Enrollment', 'success', 5000);
                }
            } else {
                // Copy to clipboard
                await navigator.clipboard.writeText(this.resource.output);
                this.showAlert(`${this.resource.label} copied to clipboard.`, 'Enrollment', 'success', 5000);
            }
            this.loading_view = undefined;
        },

        /**
         * TIMER
         */
        async startTimer(end) {
            await this.clearTimer();
            return this.setTimer(end);
        },
        
        async setTimer(end) {
            const now = new Date().getTime();
            if (now < end) {
                var diff = end - now;
                this.timer.hours = Math.floor(diff / 1000 / 60 / 60);
                diff -= this.timer.hours * 1000 * 60 * 60;
                this.timer.minutes = Math.floor(diff / 1000 / 60);
                diff -= this.timer.minutes * 1000 * 60;
                this.timer.seconds = Math.floor(diff / 1000);
                this.timer.timeout = setTimeout(this.setTimer, 1000, end);
            } else {
                this.showAlert('Your window has expired.', 'Enrollment', 'warning', 5000);
                this.$bvModal.hide('enable-enrollment');
            }
        },

        async clearTimer() {
            if (this.timer.timeout) clearTimeout(this.timer.timeout);
            this.timer = { days: 0, hours: 0, minutes: 0, seconds: 0, timeout: undefined };
        }
    }
}
</script>
